import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { TransformDomain } from "../types";
import { ReclaimSku } from "../Users";
import {
  MembershipRole as MembershipRoleDto,
  PartialTeamInvitation as PartialTeamInvitationDto,
  ReclaimApi,
  TeamInvitation as TeamInvitationDto,
  TeamMemberView as TeamMemberDto,
  TeamView as TeamDto,
} from "./client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export enum TeamMemberViewStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export enum MembershipRole {
  User = "USER",
  Admin = "ADMIN",
}

export type Team = Override<
  TeamDto,
  {
    readonly id?: number;

    paidProSeats: number;
    paidTeamSeats: number;
  }
>;

export type TeamMember = Override<
  TeamMemberDto,
  {
    readonly edition: ReclaimSku;
    readonly status: TeamMemberViewStatus;
  }
>;

export type PartialTeamInvitation = Override<PartialTeamInvitationDto, {
  role: MembershipRole;
}>;

export type TeamInvitation = TeamInvitationDto;

export const dtoToTeam = (dto: TeamDto): Team => {
  return {
    ...dto,
    paidProSeats: dto.paidProSeats || 0,
    paidTeamSeats: dto.paidTeamSeats || 0
  }
}

export const dtoToTeamMember = (dto: TeamMemberDto): TeamMember => {
  return {
    ...dto,
    edition: ReclaimSku.get(dto.edition) || ReclaimSku.Free,
    status: dto.status as unknown as TeamMemberViewStatus,
  };
};

export const invitesToDto = (invites: PartialTeamInvitation[]): PartialTeamInvitationDto[] => {
  return invites.map((i) => ({
    ...i,
    role: i.role as unknown as MembershipRoleDto
  }))
}

export class TeamDomain extends TransformDomain<Team, TeamDto> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = (): Promise<Team> => this.domainApi.team.getCurrentTeam().then(dtoToTeam);

  getMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getMembers().then((response: TeamMemberDto[]) => response.map(dtoToTeamMember));

  invite = (invites: PartialTeamInvitation[]): Promise<TeamInvitation[]> => this.domainApi.team.createInvitation(invitesToDto(invites));
}
